import { Injectable, OnDestroy } from '@angular/core';
import { Item, Set } from '@shared/models/project-details.model';
import { ResponseHandlerService } from '@shared/services/response-handler/response-handler.service';
import { Table } from 'dexie';
import { catchError, forkJoin, from, Observable, Subscription, switchMap, take, tap, throwError } from 'rxjs';
import { ProjectDataDexieService } from '@pwa/indexed-db/dexie-wrapper/project-data-dexie.service';
import { SyncDataDexieService } from '@pwa/indexed-db/dexie-wrapper/sync-data-dexie.service';
import { SYNC_CREATE_DATA_VERSION_VALUE } from '@pwa/constants/offline.constants';
import { translate, TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class ItemIdbService implements OnDestroy {
  private readonly item: Table<Item, number>;
  private ITEM_STORE_NAME = 'item';

  private readonly itemSyncDatabase: Table<Item, number>;
  private readonly itemAppDatabase: Table<Item, number>;

  translocoSub: Subscription;

  constructor(
    private projectDataDexieService: ProjectDataDexieService,
    private syncDataDexieService: SyncDataDexieService,
    readonly responseHandlerService: ResponseHandlerService,
    private translocoService: TranslocoService
  ) {
    this.item = this.projectDataDexieService.table(this.ITEM_STORE_NAME);
    this.itemSyncDatabase = this.syncDataDexieService.table(this.ITEM_STORE_NAME);
    this.itemAppDatabase = this.projectDataDexieService.table(this.ITEM_STORE_NAME);

    this.translocoSub = this.translocoService.load(this.translocoService.getActiveLang()).subscribe();
  }

  ngOnDestroy(): void {
    if (this.translocoSub) this.translocoSub.unsubscribe();
  }

  postItems(items: Item[] | undefined) {
    if (items) {
      this.logDebug('Post offline Items succeeds');
      return this.projectDataDexieService.bulkPost(items, this.item);
    }
    return;
  }

  getById(id: number | string): Observable<Item | undefined> {
    return from(this.item.where('id').equals(id).first()).pipe(
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-getting-of-item-failed'));
        console.error(translate('snacks.offline-getting-of-item-failed'), error);
        return throwError(() => error);
      })
    );
  }

  getAllActiveItemsBySetId(setId: number): Observable<Item[]> {
    return from(
      this.item
        .where('parentOfItemId')
        .equals(setId)
        .and((x: any) => !x.deleted)
        .toArray()
    ).pipe(
      take(1),
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-getting-of-all-items-by-set-id-failed'));
        console.error(translate('snacks.offline-getting-of-all-items-by-set-id-failed'), error);
        return throwError(() => error);
      })
    );
  }

  put(item: Item, projectId: number) {
    return this.getById(item.id!).pipe(
      switchMap((existingItem: Item | undefined) => {
        const offlineItem = {
          ...existingItem,
          ...item,
          projectId: Number(projectId),
        } as Item;

        const postToAppDataDb$ = this.projectDataDexieService.insertOrReplace(offlineItem, this.item);
        const postToSyncDataDb$ = this.syncDataDexieService.postItem(offlineItem);

        // field "id, "idb", "deleted" are available
        return forkJoin([postToAppDataDb$, postToSyncDataDb$]).pipe(
          switchMap(([appId, syncId]: [number | string, any]) => {
            return this.getById(appId); // Return the updated Item
          })
        );
      }),
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-update-of-item-failed'));
        console.error(translate('snacks.offline-update-of-item-failed'), error);
        return throwError(() => error);
      })
    );
  }

  /**
   * Upload a photo as a field directly in IndexedDB
   * Occur during downloading all Project photos process for offline mode
   * @param offlineItem
   */
  uploadPhoto(offlineItem: Item): Observable<string | number> {
    return this.projectDataDexieService.insertOrReplace(offlineItem, this.item).pipe(
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-upload-photo-failed'));
        console.error('[uploadPhoto] ', error);
        return throwError(() => error);
      })
    );
  }

  postItem(item: Item, projectId?: number, parent?: Set) {
    // When creating a new Item online, the BE returns the date of creation.
    // But when doing it offline, there's no server to return the date when the Item was created, so we do it manually.
    // This way when adding Items, newest ones will appear on top.
    item.creationDate = new Date().toISOString();

    return this.projectDataDexieService.getLatestRecordId(this.item).pipe(
      switchMap((latestRecordId) => {
        const newId = latestRecordId + 1;
        // field "id" is not available
        const offlineItem = {
          ...item,
          id: newId,
          version: SYNC_CREATE_DATA_VERSION_VALUE,
          // offline fields
          projectId: Number(projectId),
          hasCloudParent: parent?.version !== SYNC_CREATE_DATA_VERSION_VALUE,
        } as Item;
        return this.projectDataDexieService.insertOrReplace(offlineItem, this.item).pipe(
          switchMap((id: number | string) => this.getById(id)),
          tap((result) => {
            if (result) {
              this.syncDataDexieService.postItem(result).subscribe({
                next: (response) => {
                  this.logDebug('Post to ina_sync_put_data succeeds');
                  this.logDebug(`${response}`);
                },
                error: (error) => {
                  this.logDebug('Post to ina_sync_put_data fails');
                  this.logDebug(error);
                },
              });
            }
          }),
          catchError((error) => {
            this.responseHandlerService.handleError(translate('snacks.offline-creation-of-item-failed'));
            console.error(translate('snacks.offline-creation-of-item-failed'), error);
            return throwError(() => error); // Rethrow the error for further handling
          })
        );
      })
    );
  }

  softDelete(itemId: number | string, projectId: number) {
    if (itemId) {
      return this.getById(itemId).pipe(
        switchMap((item) => {
          const deletedItem = {
            ...item,
            deleted: true,
          } as Item;
          return this.put(deletedItem, projectId);
        })
      );
    } else {
      this.responseHandlerService.handleError(translate('snacks.offline-deletion-of-item-failed'));
      return throwError(() => 'Soft delete offline fails');
    }
  }

  private logDebug(message: string) {
    // console.debug('[item-idb.service.ts]: ' + message + '.');
  }

  /**
  Synchronization logics
   */
  bulkDeleteBySetId(setId: number): Observable<void> {
    return this.getAllBySetIdInSyncDb(setId).pipe(
      switchMap((items) => {
        const itemIds = items.map((item) => (typeof item.id === 'number' ? Number(item.id) : 0));
        return from(this.syncDataDexieService.bulkDeleteItems(itemIds));
      }),
      catchError((error) => {
        this.responseHandlerService.handleError('Error deleting items by set ID');
        console.error('Error deleting items by set ID', error);
        return throwError(() => error);
      })
    );
  }

  getAllBySetIdInSyncDb(setId: number): Observable<Item[]> {
    return from(this.itemSyncDatabase.where('parentOfItemId').equals(setId).toArray()).pipe(
      take(1),
      catchError((error) => {
        this.responseHandlerService.handleError(translate('snacks.offline-getting-of-all-items-by-set-id-failed'));
        console.error(translate('snacks.offline-getting-of-all-items-by-set-id-failed'), error);
        return throwError(() => error);
      })
    );
  }
}
