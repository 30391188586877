import { Injectable } from '@angular/core';
import { Table } from 'dexie';
import { InAReference, Item } from '@shared/models/project-details.model';
import { ProjectDataDexieService } from '@pwa/indexed-db/dexie-wrapper/project-data-dexie.service';
import { ResponseHandlerService } from '@shared/services/response-handler/response-handler.service';
import { catchError, forkJoin, from, Observable, of, switchMap, throwError } from 'rxjs';
import { SyncDataDexieService } from '@pwa/indexed-db/dexie-wrapper/sync-data-dexie.service';

@Injectable({
  providedIn: 'root',
})
export class InAReferenceIdbService {
  private readonly inAReferenceStore: Table<InAReference, number>;
  private INA_REFERENCE_STORE_NAME = 'inAReference';

  constructor(
    private projectDataDexieService: ProjectDataDexieService,
    private syncDataDexieService: SyncDataDexieService,
    readonly responseHandlerService: ResponseHandlerService
  ) {
    this.inAReferenceStore = this.projectDataDexieService.table(this.INA_REFERENCE_STORE_NAME);
  }

  createOrUpdateInAReference(item: Item): Observable<any> {
    item.projectId = Number(item.projectId);
    if (item.competitorProductItemCode && item.competitorProductManufacturer && item.projectId) {
      return this.findByCompetitorProductItemCodeAndCompetitorProductManufacturerAndProjectId(
        item.competitorProductItemCode,
        item.competitorProductManufacturer,
        item.projectId
      ).pipe(
        switchMap((existingInAReference) => {
          if (existingInAReference) {
            return this.put(item, existingInAReference.id, existingInAReference.version);
          } else {
            return this.post(item);
          }
        }),
        catchError((error) => {
          console.error('saveOrUpdateItem', error);
          return throwError(() => error);
        })
      );
    } else {
      return of(undefined);
    }
  }

  put(item: Item, inAReferenceId?: number, inAReferenceVersion?: number): Observable<[string | number, string | number]> {
    const inAReference: InAReference = this.buildInAReference(item);
    inAReference.id = inAReferenceId;
    inAReference.version = inAReferenceVersion;
    return forkJoin([
      this.projectDataDexieService.insertOrReplace(inAReference, this.inAReferenceStore),
      this.syncDataDexieService.storeInAReference(inAReference),
    ]).pipe(
      catchError((error) => {
        console.error('PUT InAReference offline failed', error);
        return throwError(() => error);
      })
    );
  }

  post(item: Item): Observable<[string | number, string | number]> {
    const inAReference: InAReference = this.buildInAReference(item);
    inAReference.version = -1;
    return forkJoin([
      this.projectDataDexieService.insertOrReplace(inAReference, this.inAReferenceStore),
      this.syncDataDexieService.storeInAReference(inAReference),
    ]).pipe(
      catchError((error) => {
        console.error('POST InAReference offline failed', error);
        return throwError(() => error);
      })
    );
  }

  findByCompetitorProductItemCodeAndCompetitorProductManufacturerAndProjectId(
    itemCode: string,
    manufacturer?: string,
    projectId?: string | number
  ): Observable<InAReference | undefined> {
    return from(
      this.inAReferenceStore
        .where('competitorProductItemCode')
        .equals(itemCode)
        .and((inAReference) => inAReference.competitorProductManufacturer === manufacturer)
        .and((inAReference) => inAReference.projectId === Number(projectId))
        .first()
    ).pipe(
      catchError((error) => {
        console.error('searchInAReferences', error);
        return throwError(() => error);
      })
    );
  }

  searchInAReferences(searchString: string, projectId: number | string): Observable<InAReference[]> {
    return from(
      this.inAReferenceStore
        .where('competitorProductItemCode')
        .startsWithIgnoreCase(searchString)
        .and((inAReference) => inAReference.projectId === +projectId)
        .limit(5)
        .toArray()
    ).pipe(
      catchError((error) => {
        console.error('searchInAReferences', error);
        return throwError(() => error);
      })
    );
  }

  getAllByProjectId(projectId: number | string): Observable<InAReference[]> {
    return from(this.inAReferenceStore.where('projectId').equals(projectId).toArray()).pipe(
      catchError((error) => {
        console.error('getAllByProjectId', error);
        return throwError(() => error);
      })
    );
  }

  bulkPostInAReference(inAReferences: InAReference[]) {
    return inAReferences ? this.projectDataDexieService.bulkPost(inAReferences, this.inAReferenceStore) : of([]);
  }

  /**
   Synchronization logics
   */
  bulkDeleteByProjectId(projectId: number | string) {
    return this.getAllByProjectId(projectId).pipe(
      switchMap((inAReferences) => {
        const ids = inAReferences.map((item) => (typeof item.id === 'number' ? Number(item.id) : 0));
        return from(this.syncDataDexieService.bulkDeleteInAReferences(ids));
      }),
      catchError((error) => {
        this.responseHandlerService.handleError('Error bulkDeleteByProjectId');
        console.error('Error bulkDeleteByProjectId', error);
        return throwError(() => error);
      })
    );
  }

  private buildInAReference(item: Item): InAReference {
    return {
      /**
       * Competitor Product data
       */
      competitorProductItemCode: item.competitorProductItemCode,
      competitorProductManufacturer: item.competitorProductManufacturer,
      competitorProductDescription: item.competitorProductDescription,
      /**
       * BBraun Product data
       */
      bBraunProductItemCode: item.bBraunProductItemCode,
      bBraunProductDescription: item.bBraunProductDescription,
      bBraunProductStatus: item.bBraunProductStatus,
      /**
       * Reference data
       */
      referenceSimilarity: item.referenceSimilarity,
      /**
       * Item data
       */
      projectId: item.projectId,
      itemType: item.itemType,
      productClass: item.productClass,
      hasCustomProduct: item.hasCustomProduct,
    } as InAReference;
  }
}
